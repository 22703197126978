import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import styled from "styled-components";
import { Wrapper } from "./configurator-hero-style";
import { useProjectContext } from "../../../context/project-context";
import { ProductInformation } from "../../../components/common";
import { StyledText } from "../../../components/common";
import { ReactComponent as BeTa } from "../../../assets/images/beta.svg";

export const BetaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 50px;
  div {
    a {
      text-decoration: none;
      color: white;
    }
  }
`;
export const ConfiguratorHero = () => {
  const navigate = useNavigate();
  const {
    data: { title, price },
    logout,
    currentUser,
    updateState,
  } = useProjectContext();
  const [currentUserName, setCurrentUserName] = useState("");

  useEffect(() => {
    const userName = window.localStorage.getItem("userName");
    setCurrentUserName(userName);
    
  }, [currentUser?.uid]);

  function ButtonAction() {
    
    logout()
    //updateState({ isThreeLoaded: false, threeApp: null });
    navigate("/configurator");
  }
  // useEffect(()=>{console.log(currentUserName)},[currentUserName])



  return (
    <Wrapper>
      <BetaHeader>
        <StyledText
          fontSize="16px"
          fontWeight={400}
          cursor="pointer"
          style={{ marginRight: "50px", fontFamily: "Inter" }}
        >
          {currentUserName && 
          <p onClick={ ButtonAction  }>
            {"Sign out"}
          </p>}
          {!currentUserName && 
          <a href="/sign-up">
             Sign in</a> 
          }
        
        </StyledText>
        <BeTa />
      </BetaHeader>

      <ProductInformation
        productName={title}
        producer={currentUserName}
        price={price}
      />
    </Wrapper>
  );
};
