import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Logo} from '../../../assets/images/SELF_logo_v2.svg';
import {StyledText} from '../../../components/common/styled-text/styled-text';
const HomeFooterWrapper = styled.div`
  margin: 130px 0px 50px 0px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    margin: 20px 0px 10px 0px;
  }
  @media (max-width: 414px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    svg {
      height: 20px;
      width: 80px;
      margin-bottom: 10px;
    }
  }
`;
export const HomeFooter = () => {
  return (
    <HomeFooterWrapper>
      <Logo style={{marginRight: '56px'}} />
      <StyledText fontSize="16px" lineHeight="19.2px">
        © 2021 Maison Ogé. Towards Infinity.
      </StyledText>
    </HomeFooterWrapper>
  );
};
