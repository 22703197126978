import styled from 'styled-components';
import ArrowForward from '@mui/icons-material/ArrowForward';
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Aeonik';
  width: fit-content;

  &:hover {
    svg {
      fill: #f5f4f1;
    }
    span {
      color: #f5f4f1;
    }
  }
  span {
    font-size: 35px;
    color: #626262;
    border-bottom: 1px solid;
  }
  svg {
    fill: #626262;
  }
`;
export const NextButton = ({onClick, content}) => {
  return (
    <Wrapper onClick={onClick}>
      <span>{content}</span>
      <ArrowForward style={{marginLeft: '10px', marginTop: '5px'}} />
    </Wrapper>
  );
};
