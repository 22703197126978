import commands from "path-ast/lib/keys";
import modular from "simple-modular-scale";

let scale = modular({
  base: 8,
  ratios: [9 / 8, 4 / 3, 4 / 3],
});

const Data = {
  title: "Paths",
  commands: Object.keys(commands).filter(function (key) {
    return key.match(/[A-Z]/);
  }),
  padding: 0,
  scale: scale,
  styles: {
    pad: scale,
  },
  colors: {
    cyan: "cyan",
    blue: "#0cf",
    dark: "#222",
    darken: [
      "#000",
      "#000",
      "#000",
      "#000",
      "#000",
      "#000",
      "#000",
      "#000",
      "#000",
    ],
    lighten: ["#fff", "#fff", "#fff", "#fff"],
  },
};
export default Data;
