import React, {useState, useEffect} from 'react';
import MobileScreen from '../../components/common/mobile-screen/mobile-screen';
import {HomeContent} from './home-content/home-content';
import {HomeHero} from './home-hero/home-hero';
import {HomeWrapper} from './home-style';

export const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  return (
    <>
    <div style={{height: "100vh", width:"100vw", overflow: "hidden"}}>
    <iframe src="https://inconsistency-hold-518429.framer.app"  height="100%" width="100%"></iframe>

      {/* {isMobile ? (
        <MobileScreen />
      ) : (
        <HomeWrapper>
          <HomeHero />
          <HomeContent />
        </HomeWrapper>
      )} */}
      </div>
    </>
  );
};
