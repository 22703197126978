import React from 'react';
import {Link} from 'react-router-dom';
import {StyledText} from '../styled-text/styled-text';

export const SignPageFooter = ({isSignUp}) => {
  return (
    <div style={{marginBottom: '-100px', display: 'flex', flexDirection: 'row'}}>
      {isSignUp ? (
        <StyledText>
          Already have an account?
          <Link to="/sign-in" style={{marginLeft: '10px', color: '#f5f4f1'}}>
            Sign in
          </Link>
        </StyledText>
      ) : (
        <StyledText>
          Don't have an account?
          <Link to="/sign-up" style={{marginLeft: '10px', color: '#f5f4f1'}}>
            Sign Up
          </Link>
        </StyledText>
      )}
    </div>
  );
};
