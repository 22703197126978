import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import ArrowForward from "@mui/icons-material/ArrowForward";
import styled from "styled-components";
import { Paypal } from "../../common/paypal/paypal";
import { FakeCheckout } from "../../common/paypal/FakeCheckout";
import { useProjectContext } from "../../../context/project-context";
const ButtonWrapper = styled.div`
  margin-top: 50px;


  button {
    width: 100%;

    border: none;
    color: #181818 !important;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background-color: #4dd6a7;
    }
    padding: 31.5px 0px;
    background-color: #ffffff;
    border-radius: 10px;
    text-transform: none;
    font-family: "Inter";
  }
`;
const AStyledButton = styled.a`
display: inline-flex;
width: 100%;
justify-content: center;
padding:  37px 0px;
background-color: #fff;
color: #000;
border-radius: 10px;
text-align: center;
text-decoration: none;
font-weight: 600;
font-family: "Inter";
font-size: 20px;
cursor: pointer;
align-items: center;
letter-spacing: 0.2px;
`
const ScrollContainer = styled.div`

max-height: 45vh; /* Set the maximum height for the container */
overflow-y: auto;
background-color: rgba(256,256,256);
border-radius: 10px;
padding: 10px 10px 0px 10px;


/* Customize the scrollbar */
&::-webkit-scrollbar {
  width: 5px;
}

/* Customize the scrollbar track */
&::-webkit-scrollbar-track {
  background-color: ##888;
  border-radius: 5px;
}

/* Customize the scrollbar thumb */
&::-webkit-scrollbar-thumb {
  background-color: rgba(136,136,136,0.5);
  border-radius: 5px;
}

/* Customize the scrollbar thumb on hover */
&::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
`;

export const AddToCart = () => {
  const navigate = useNavigate();
  const {
    state: { isCheckout },
    updateState,
    currentUser,
  } = useProjectContext();






  return (
    <div >
      <ButtonWrapper>
        {isCheckout ? (
          <ScrollContainer>

          <Paypal />
          </ScrollContainer>
          // <FakeCheckout />
        ) : (
          <>
          {currentUser?.uid &&
          <Button
            endIcon={<ArrowForward />}
            onClick={() => updateState({ isCheckout: true })
             
            }
          >
            {"Check out" }
          </Button> }
          {!currentUser?.uid &&
         
         <AStyledButton href="/sign-up">

     Sign in <ArrowForward style={{fontSize:"20px", marginLeft:"10px"}} />
         </AStyledButton>
         
          }
          </>
        )}
      </ButtonWrapper>
    </div>
  );
};
