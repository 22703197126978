import React from 'react';
import styled from 'styled-components';
import {StyledText} from '../../../components/common/styled-text/styled-text';

import {ReactComponent as HomeImg} from '../../../assets/images/home-image.svg';

import {HomeFooter} from '../home-footer/home-footer';
const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .home-content {
    font-size: 107px;
    line-height: 115px;
    @media (max-width: 1600px) {
      font-size: 80px;
      line-height: normal;
      padding: 180px 0px 100px 0px;
    }
    @media (max-width: 1400px) {
      font-size: 70px;
      line-height: 110px;
      padding: 180px 0px 100px 0px;
    }
    @media (max-width: 1100px) {
      font-size: 65px;
    }
    @media (max-width: 1000px) {
      font-size: 60px;
    }
    @media (max-width: 890px) {
      font-size: 55px;
    }
    @media (max-width: 815px) {
      font-size: 50px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
      line-height: 90px;
      padding: 180px 0px 200px 0px;
    }
    @media (max-width: 630px) {
      font-size: 35px;
      line-height: 70px;
    }
    @media (max-width: 573px) {
      font-size: 30px;
      line-height: 60px;
    }
    @media (max-width: 450px) {
      font-size: 27px;
      line-height: 60px;
    }
   
    @media (max-width: 414px) {
      font-size: 20px;
      line-height: 50px;
      padding: 50px 0px 40px 0px;
    }
    @media (max-width: 360px) {
      font-size: 20px;
      line-height: 50px;
      padding: 139px 0px 40px 0px; */
    } 
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
  @media (max-width: 414px) {
    height: 100%;
  }
`;
const HomeImgWrapper = styled.div`
  position: absolute;
  top: -43px;
  right: 213px;
  @media (max-width: 1600px) {
    top: 120px;
    right: 130px;
    svg {
      width: 570px;
    }
  }
  @media (max-width: 1400px) {
    top: 120px;
    right: 130px;
    svg {
      width: 570px;
    }
  }
  @media (max-width: 1100px) {
    top: 120px;
    right: 130px;
    svg {
      width: 570px;
    }
  }
  @media (max-width: 768px) {
    top: 90px;
    right: 30px;
    svg {
      width: 450px;
    }
  }
  @media (max-width: 570px) {
    top: 30px;
    right: 30px;
    svg {
      width: 350px;
    }
  }
  @media (max-width: 450px) {
    top: 15px;
    right: 30px;
    svg {
      width: 300px;
    }
  }
  @media (max-width: 414px) {
    top: 35px;
    right: 30px;
    svg {
      width: 250px;
      height: 285px;
    }
  }
  @media (max-width: 360px) {
    top: 135px;
    right: 20px;
    svg {
      width: 250px;
      height: 285px;
    }
  }
`;
export const HomeContent = () => {
  return (
    <Wrapper>
      <div style={{position: 'relative'}}>
        <StyledText className="home-content" color="#444444">
          Your space is personal. Shouldn't <br />
          your furniture be as well? We're <br />
          building the first ever furniture line <br />
          designed for You, by You. Your <br />
          space will never be the same
        </StyledText>
        <HomeImgWrapper>
          <HomeImg />
        </HomeImgWrapper>
      </div>
      <HomeFooter />
    </Wrapper>
  );
};
