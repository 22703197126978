import React from "react";
import styled from "styled-components";
import { assets } from "../../../assets/assets";
const LogoWrapper = styled.div`
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;
export const LoGo = () => {
  return (
    <a href="http://selffurnished.com/">
      <LogoWrapper>
        <img src={assets.svgs.LogoIcon} alt="" />
      </LogoWrapper>
    </a>
  );
};
