import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StyledText } from "../styled-text/styled-text";

const SvgpathWrapper = styled.div`
  & ::-webkit-scrollbar {
    width: 5px;
  }
  & ::-webkit-scrollbar-track {
    opacity: 0;
    border-radius: 10px;
  }
  & ::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background: #6b6b6b;
  }
`;
export const CopySvgPath = () => {
  const [svgMarkup, setSvgMarkup] = useState("");

  // const [isCopied, setIsCopied] = useState(false);

  // const copySvgData = () => {
  //   const el = document.createElement('textarea');
  //   el.value = data.svgData;
  //   el.setAttribute('readonly', '');
  //   el.style.position = 'absolute';
  //   el.style.left = '-9999px';
  //   document.body.appendChild(el);
  //   el.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(el);
  //   setIsCopied(true);
  //   setTimeout(() => {
  //     setIsCopied(false);
  //   }, 1500);
  // };
  useEffect(() => {
    const svgMarkup = document.getElementById("blobSvg").outerHTML;
    setSvgMarkup(svgMarkup);
  }, [svgMarkup]);
  return (
    <>
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '100px',
          marginBottom: '5px',
          justifyContent: 'space-between',
        }}
      >
        <IconButton onClick={copySvgData}>
          <ContentCopyIcon />
        </IconButton>
        {isCopied && <StyledText color="#fd79a8">Copied</StyledText>}
      </div> */}
      <SvgpathWrapper>
        <StyledText
          lineHeight="20px"
          fontSize="14px"
          style={{ height: "120px", overflowY: "scroll", overflowX: "hidden" }}
        >
          {document.getElementById("blobSvg") &&
            document.getElementById("blobSvg").outerHTML}
        </StyledText>
      </SvgpathWrapper>
    </>
  );
};
