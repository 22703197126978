import { useState } from "react";
import { addUser } from "../../firebase/authentication-method/authentication-methods";

import { useNavigate, Navigate } from "react-router-dom";
import { useProjectContext } from "../../context/project-context";
import { SignInWrapper } from "../../components/common";
import { InputPlaceholderWrapper } from "../../components/common";
import { StyledText } from "../../components/common";
import { SignLogo } from "../../components/container";
import { SignPageFooter } from "../../components/common";
import { NextButton } from "../../components/common";

import { validateEmail } from "../../utils/validateEmailAddress";
import { Error } from "../../components/common/error/error";
import { useDebounce } from "../../hooks/use-debounce";
import CircularProgress from "@material-ui/core/CircularProgress";

export const SignUp = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [beta, setBeta] = useState("");
  const [isBetaChecked, setIsBetaChecked] = useState("");
  const [isEmailSubmited, setIsEmailSubmited] = useState(false);
  const [isSecondStepPassed, setIsSecondStepPassed] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signup, updateState } = useProjectContext();

  const debouncedEmail = useDebounce(email, 1500);

  const handleSubmit = async () => {
    try {
      setError("");
      setLoading(true);

      await signup(debouncedEmail, password).then((results) => {
        console.log(results.user.uid)
        const user_id = results.user.uid;
        const updateData = {
          email: debouncedEmail,
          name: fullName,
          id: user_id,
        };

        addUser(updateData);
        window.localStorage.setItem("userName", fullName);
        window.localStorage.setItem("userId", updateData.id);
      });
       updateState({ isThreeLoaded: false, threeApp: null });
      navigate("/configurator");
    } catch (e) {
      setError(`Failed to create an account.    
       ${e.message}`);
    }
    setLoading(false);
  };

  const goToSignup = () => {
    return <Navigate to="/sign-up" replace="true"/>;
  }


  return (
    <SignInWrapper>
      <SignLogo />
      <div>
        {!isBetaChecked && (
          <>
            <InputPlaceholderWrapper>
              <input
                autoComplete="off"
                type="password"
                name="beta-password"
                required
                spellCheck="false"
                value={beta}
                onChange={(e) => {
                  setBeta(e.target.value);
                  setError("");
                }}
              />
              <span className="placeholder">
                <StyledText fontSize="45px">Beta Password</StyledText>
              </span>
            </InputPlaceholderWrapper>
            <NextButton
              content="Next"
              onClick={() => {
                if (beta === process.env.REACT_APP_BETA_PASSWORD)
                  setIsBetaChecked(true);
                else
                  setError(
                    "Invalid password. Please contact marv@designwithself.com for access."
                  );
              }}
            />
          </>
        )}
        {!isEmailSubmited && isBetaChecked && (
          <>
            <InputPlaceholderWrapper>
              <input
                autoComplete="off"
                type="text"
                name="email"
                required
                spellCheck="false"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="placeholder">
                <StyledText fontSize="45px">Email address</StyledText>
              </span>
            </InputPlaceholderWrapper>
            <NextButton
              content="Next"
              onClick={() => {
                if (debouncedEmail !== "" && validateEmail(debouncedEmail)) {
                  setIsEmailSubmited(true);
                  setError("");
                }
              }}
            />
            {debouncedEmail !== "" && !validateEmail(debouncedEmail) && (
              <Error errorMessage="Enter a valid email address." />
            )}
          </>
        )}
        {isEmailSubmited && !isSecondStepPassed && (
          <>
            <InputPlaceholderWrapper>
              <input
                autoComplete="off"
                type="name"
                name="full-name"
                required
                spellCheck="false"
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);
                  setError("");
                }}
              />
              <span className="placeholder">
                <StyledText fontSize="45px">First and Last name</StyledText>
              </span>
            </InputPlaceholderWrapper>
            <NextButton
              content="Next"
              onClick={() => {
                if (fullName) {
                  setIsSecondStepPassed(true);
                  setError("");
                } else {
                  setError("Please enter your full name");
                }
              }}
            />
          </>
        )}
        {isSecondStepPassed && (
          <>
            <InputPlaceholderWrapper>
              <input
                autoComplete="off"
                type="password"
                name="password"
                required
                spellCheck="false"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
              />
              <span className="placeholder">
                <StyledText fontSize="45px">Password</StyledText>
              </span>
            </InputPlaceholderWrapper>
            <NextButton
              content="Sign up"
              onClick={() => {
                if (password.length > 6) handleSubmit();
                else setError("Password should be more than 6 letters");
              }}
            />

            {loading && <CircularProgress style={{ marginTop: "20px" }} />}
          </>
        )}
        <Error errorMessage={error} />
      </div>
      <SignPageFooter isSignUp={true} />
    </SignInWrapper>
  );
};
