import styled from 'styled-components';

export const InputPlaceholderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;

  input {
    font-weight: 500;
    font-size: 45px;
    color: #f5f4f1;
    padding: 15px 0px;
    border: 0;
    background-color: black;
    font-family: 'Aeonik';
    /* text-align: center; */
    /* width: 500px; */
    @media (max-width: 768px) {
      width: 90vw;
    }
  }

  span.placeholder {
    position: absolute;
    margin: 17px 0;
    color: #f5f4f1;
    font-size: 45px;
    top: 0;
    transition: all ease-in 0.3s;
    pointer-events: none;
  }

  input:valid + span.placeholder,
  input:focus + span.placeholder {
    /* transform: scale(0.6) translate(-110px, -90px); */
    transform: scale(0.6) translateY(-90px);
    transform-origin: left;
  }
  input:focus + span.placeholder {
    transform: scale(0.6) translateY(-90px);
    transform-origin: left;
  }
  input:focus {
    outline: none;
  }
  span.placeholder div {
    letter-spacing: 0.15rem;
  }
  input:focus + span.placeholder div {
    color: #626262;
  }
`;
