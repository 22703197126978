import { assets } from "../../../../assets/assets";
import styled, { css } from "styled-components";
import { useProjectContext } from "../../../../context/project-context";

const Container = styled.div`
  width: 96%;
  display: flex;
  justify-content: space-between;
`;

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: ${({ active }) => (active ? "pointer" : "not-allowed")};
  ${({ active }) =>
    active &&
    css`
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
      &:active {
        opacity: 1;
      }
    `}

  & > img {
    opacity: ${({ active }) => (active ? 1 : 0.5)};
  }
  & > span {
    padding-left: 8px;
    color: white;
    opacity: ${({ active }) => (active ? 1 : 0.5)};
    font-family: Inter;
    font-weight: 400;
  }
`;

export const HistoryPanel = ({
  extraStyle,
  history = [],
  svgHistory = [],
  setHistory,
}) => {
  const {
    data: { type, historyCount },
    updateData,
  } = useProjectContext();

  return (
    <div style={{ fontSize: "14px", ...extraStyle }}>
      <Container>
        <Item
          active={historyCount > 1}
          onPointerDown={() => {
            if (historyCount < 2) return;
            updateData({
              historyCount: historyCount - 1,
              ...(type === 1
                ? { svgPath: svgHistory[historyCount - 2] }
                : { ...history[historyCount - 2] }),
            });
          }}
        >
          <img src={assets.svgs.UndoIcon} alt="" />
          <span>Undo</span>
        </Item>

        <Item
          active={
            historyCount < (type === 1 ? svgHistory.length : history.length)
          }
          onPointerDown={() => {
            if (
              historyCount >
              (type === 1 ? svgHistory.length : history.length) - 1
            )
              return;

            updateData({
              historyCount: historyCount + 1,
              ...(type === 1
                ? { svgPath: svgHistory[historyCount] }
                : { ...history[historyCount] }),
            });
          }}
        >
          <img src={assets.svgs.RedoIcon} alt="" />
          <span>Redo</span>
        </Item>

        {type === 1 ? (
          <Item
            active={true}
            onPointerDown={() => {
              updateData({
                historyCount: 1,
                svgPath:
                  "M67.5,405Q90, 405, 225, 405Q360, 405, 382.5, 405Q405, 405, 405, 382.5Q405, 360, 405, 225Q405, 90, 405, 67.5Q405, 45, 382.5, 45Q360, 45, 225, 45Q90, 45, 67.5, 45Q45, 45, 45, 67.5Q45, 90, 45, 225Q45, 360, 45, 382.5Q45, 405, 67.5, 405Z",
              });
            }}
          >
            <img src={assets.svgs.Reset} alt="" />
            <span>Reset</span>
          </Item>
        ) : (
          <Item
            active={true}
            onPointerDown={() => {
              let growth, complexity;
              if (type === 2) {
                growth = getRandomNumber(135, 245);
                complexity = parseInt(getRandomNumber(4, 10));
              } else if (type === 3) {
                growth = getRandomNumber(6, 8);
                complexity = getRandomNumber(12, 20);
              }
              updateData({ growth, complexity });
              if (setHistory)
                setHistory((prev) => [...prev, { complexity, growth }]);
            }}
          >
            <img src={assets.svgs.DiceIcon} alt="" />
            <span>Random</span>
          </Item>
        )}
      </Container>
    </div>
  );
};

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min + Math.random();
}
