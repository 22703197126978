import React from "react";
import styled from "styled-components";
import loading from "../../../assets/images/blobgif.gif";
const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
`;
const LogoPanel = styled.div`
  padding: 55px 45px;
  display: flex;
  justify-content: space-between;
`;
const LoadingPanel = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Loading = () => {
  return (
    <Wrapper>
      <LogoPanel></LogoPanel>

      <LoadingPanel>
        <img src={loading} alt="loading" />
      </LoadingPanel>
    </Wrapper>
  );
};
