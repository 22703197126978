import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// Initialize FirebasefirebaseConfig
export const app = initializeApp({
  apiKey: "AIzaSyBnApjnYsmJ65LHEJk9bztvldfMH1H9LLE",
  authDomain: "blobpro.firebaseapp.com",
  projectId: "blobpro",
  storageBucket: "blobpro.appspot.com",
  messagingSenderId: "145084776702",
  appId: "1:145084776702:web:b0b37b831374eeacaf25af",
});

export const db = getFirestore();
