import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Wrapper,
  ControlWrapper,
  SvgWrapper,
  SvgWrapperCore, 
  Warning,
} from "./control-style";
import { LoGo } from "../../common/logo/logo";
import { generator, _createSvgPath } from "../../../utils/generate-blob";
import { generateStar } from "../../../utils/generate-star";
import { useProjectContext } from "../../../context/project-context";

import { getDesigns } from "../../../firebase/authentication-method/authentication-methods";

import { assets } from "../../../assets/assets";
import TabPanel from "./tab-panel";
import { FileOptions } from "./file-options";
import { CameraView } from "./camera-view";
import { DrawSVG, expandPts } from "../../common/draw-svg/draw-svg";
import { HistoryPanel } from "./comps/history-panel";
import { HSlider } from "./comps/hslider";
import { DropdownSelector } from "./comps/dropdown-selector";
import { ColorPanel } from "./comps/color-panel";
import { TabSelector } from "./comps/tab-selector";

export const Control = () => {
  const {
    data: {
      material,
      legType,
      svgPath,
      type,
      growth,
      complexity,
      tableColor,
      points,
      borderRadius,
    
    },
    updateData,
    updateState,
    myDesigns,
    setMyDesigns
  } = useProjectContext();

  const [isDesignSaved, setIsDesignSaved] = useState(false);
  const [isDesignDeleted, setIsDesignDeleted] = useState(false);
  //const [myDesigns, setMyDesigns] = useState([]);
  const [currentUserId, setCurrentUser] = useState("");
  const [svgImage, setSvgImage] = useState("");
  const svgDomElement = useRef(null);
  const legTypeNames = useMemo(
    () => ["Leg Type 01", "Leg Type 02", "Leg Type 03", "Leg Type 04"],
    []
  );

  useEffect(() => {
    const userId = window.localStorage.getItem("userId");
    setCurrentUser(userId);
  }, []);

  useEffect(() => {
    const getSavedDesigns = async () => {
      const results = await getDesigns(currentUserId);
      setMyDesigns(() => results);
    };
    getSavedDesigns();
  }, [currentUserId, isDesignSaved, isDesignDeleted]);

  const [history, setHistory] = useState([]);
  const [svgHistory, setSvgHistory] = useState([
    "M67.5,405Q90, 405, 225, 405Q360, 405, 382.5, 405Q405, 405, 405, 382.5Q405, 360, 405, 225Q405, 90, 405, 67.5Q405, 45, 382.5, 45Q360, 45, 225, 45Q90, 45, 67.5, 45Q45, 45, 45, 67.5Q45, 90, 45, 225Q45, 360, 45, 382.5Q45, 405, 67.5, 405Z",
  ]);

  useEffect(() => {
    updateData({
      historyCount: type === 1 ? svgHistory.length : history.length,
    });
  }, [history, svgHistory, updateData, type]);

  // update svg shape when tab changed
  useEffect(() => {
    if (type === 3) {
      const { path } = generator({
        size: 500,
        growth: parseInt(growth),
        edges: parseInt(complexity),
        seed: null,
      });
      updateData({ svgPath: path });
    } else if (type === 2) {
      const path = generateStar(parseInt(growth), parseInt(complexity));
      updateData({ svgPath: path });
    } else {
      //updateData({ svgPath: "M45 405 L405 405 L405 45 L45 45 Z" });
      updateData({
        svgPath:
          "M67.5,405Q90, 405, 225, 405Q360, 405, 382.5, 405Q405, 405, 405, 382.5Q405, 360, 405, 225Q405, 90, 405, 67.5Q405, 45, 382.5, 45Q360, 45, 225, 45Q90, 45, 67.5, 45Q45, 45, 45, 67.5Q45, 90, 45, 225Q45, 360, 45, 382.5Q45, 405, 67.5, 405Z",
      });
    }

    updateState({ isCheckout: false });
    setSvgImage(document.getElementById("blobSvg").outerHTML);
  }, [growth, complexity, updateState, updateData, type]);

  return (
    <Wrapper>
      <LoGo />
      <ControlWrapper>
        <SvgWrapper>
          <SvgWrapperCore>
            <svg
              viewBox="0 0 500 500"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="100%"
              id="blobSvg"
              ref={svgDomElement}
            >
              {type !== 1 && (
                <path
                  id="blob"
                  d={svgPath}
                  fill="none"
                  stroke="#FFFFFF"
                  strokeWidth="2px"
                  strokeLinejoin="round"
                ></path>
              )}
            </svg>
            {type === 1 && <DrawSVG setSvgHistory={setSvgHistory} />}
          </SvgWrapperCore>
        </SvgWrapper>

        {/* -------------- TAB 1 -------------- */}
        <TabPanel value={type} index={1}>
        <Warning>
            <img src={assets.svgs.WarningIcon} alt="" />
            <div style={{ fontSize: "12px", fontFamily: "Inter" }}>
              Move points or click anywhere on the line to add a point. Use up
              to 10 points to define a shape. Don’t intersect the dotted line.
            </div>
          </Warning>
          <TabSelector setHistory={setHistory} setSvgHistory={setSvgHistory} />



          <HistoryPanel
            history={history}
            svgHistory={svgHistory}
            setHistory={setHistory}
            extraStyle={{ marginTop: "20px" }}
          />

          <DropdownSelector
            value={material}
            options={["Plywood", "Steel"]}
            extraStyle={{ marginTop: "20px" }}
            onChange={(v) => {
              updateData({ price: v === 2 ? 550 : 399 });
              updateData({ material: ["Plywood", "Steel"][v - 1] });
            }}
          />

          <HSlider
            extraStyle={{ marginTop: "20px" }}
            value={borderRadius}
            startIcon={assets.svgs.RadiusStart}
            endIcon={assets.svgs.RadiusEnd}
            min={0.1}
            max={50}
            step={0.1}
            label={"Radius"}
            onChange={(v) =>
              updateData({
                borderRadius: v,
                svgPath: _createSvgPath(expandPts(points, v)),
              })
            }
          />

          <DropdownSelector
            extraStyle={{ marginTop: "20px" }}
            value={legTypeNames[legType - 1]}
            options={legTypeNames}
            onChange={(v) => updateData({ legType: v })}
          />

          {material === "Steel" && (
            <ColorPanel
              value={tableColor}
              onChange={(color) => updateData({ tableColor: color })}
            />
          )}
        </TabPanel>

        {/* -------------- TAB 2 -------------- */}
        <TabPanel value={type} index={2}>
          <TabSelector setHistory={setHistory} setSvgHistory={setSvgHistory} />
          <HistoryPanel history={history} setHistory={setHistory} />
          <DropdownSelector
            value={material}
            options={["Plywood", "Steel"]}
            extraStyle={{ marginTop: "20px" }}
            onChange={(v) => {
              updateData({ price: v === 2 ? 550 : 399 });
              updateData({ material: ["Plywood", "Steel"][v - 1] });
            }}
          />
          <HSlider
            extraStyle={{ marginTop: "20px" }}
            value={growth}
            startIcon={assets.svgs.Type2_left_icon_1}
            endIcon={assets.svgs.Type2_left_icon_2}
            min={135}
            max={245}
            step={0.01}
            label={"Shape"}
            onChange={(v) => updateData({ growth: v })}
            onPointerUp={() => {
              setHistory((prev) => [...prev, { complexity, growth }]);
            }}
          />

          <HSlider
            extraStyle={{ marginTop: "20px" }}
            value={complexity}
            startIcon={assets.svgs.Type2_right_icon_1}
            endIcon={assets.svgs.Type2_right_icon_2}
            min={4}
            max={10}
            label={"Points"}
            onChange={(v) => updateData({ complexity: v })}
            onPointerUp={() => {
              setHistory((prev) => [...prev, { complexity, growth }]);
            }}
          />

          <DropdownSelector
            value={legTypeNames[legType - 1]}
            extraStyle={{ marginTop: "20px" }}
            options={legTypeNames}
            onChange={(v) => updateData({ legType: v })}
          />

          {material === "Steel" && (
            <ColorPanel
              value={tableColor}
              onChange={(color) => updateData({ tableColor: color })}
            />
          )}
        </TabPanel>

        {/* -------------- TAB 3 -------------- */}
        <TabPanel value={type} index={3}>
          <TabSelector setHistory={setHistory} setSvgHistory={setSvgHistory} />
          <HistoryPanel history={history} setHistory={setHistory} />

          <DropdownSelector
            value={material}
            options={["Plywood", "Steel"]}
            extraStyle={{ marginTop: "20px" }}
            onChange={(v) => {
              updateData({ price: v === 2 ? 550 : 399 });
              updateData({ material: ["Plywood", "Steel"][v - 1] });
            }}
          />

          <HSlider
            extraStyle={{ marginTop: "20px" }}
            value={growth}
            startIcon={assets.svgs.Angle}
            endIcon={assets.svgs.Angles}
            min={6}
            max={8}
            step={0.08}
            label={"Shape"}
            onChange={(v) => updateData({ growth: v })}
            onPointerUp={() => {
              setHistory((prev) => [...prev, { complexity, growth }]);
            }}
          />

          <HSlider
            extraStyle={{ marginTop: "20px" }}
            value={complexity}
            startIcon={assets.svgs.Circle}
            endIcon={assets.svgs.CircleEnd}
            min={12}
            max={20}
            step={0.1}
            label={"Points"}
            onChange={(v) => updateData({ complexity: v })}
            onPointerUp={() => {
              setHistory((prev) => [...prev, { complexity, growth }]);
            }}
          />

          <DropdownSelector
            extraStyle={{ marginTop: "20px" }}
            value={legTypeNames[legType - 1]}
            options={legTypeNames}
            onChange={(v) => updateData({ legType: v })}
          />

          {material === "Steel" && (
            <ColorPanel
              value={tableColor}
              onChange={(color) => updateData({ tableColor: color })}
            />
          )}
        </TabPanel>

        <FileOptions
          complexity={complexity}
          growth={growth}
          legType={legType}
          svgPath={svgPath}
          svgImage={svgImage}
          myDesigns={myDesigns}
          type={type}
          currentUserId={currentUserId}
          setIsDesignSaved={setIsDesignSaved}
          setIsDesignDeleted={setIsDesignDeleted}
        />

        <CameraView />

        {/* <ControlPanelWrapper>
          <ExtraControlButtons
            growth={growth}
            complexity={complexity}
            type={type}
          />
          <CopySvgPath />
        </ControlPanelWrapper> */}
      </ControlWrapper>
    </Wrapper>
  );
};
