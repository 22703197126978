// import {styled, Typography} from '@material-ui/core';
import styled from 'styled-components';
export const StyledText = styled.div`
  font-family: 'Aeonik';
  line-height: ${(props) => props.lineHeight};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.color ? props.color : '#F5F4F1')};
  cursor: ${(props) => (props.cursor ? 'pointer' : 'null')};
  border-bottom: ${(props) => (props.borderBottom ? '1px solid' : 'none')};
  font-weight: ${(props) => props.fontWeight};
`;
