import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 55px 48px 0px 38px;
  @media (max-width: 1440px) {
    padding: 55px 22px 0px 22px;
  }
  @media (max-width: 1024px) {
    padding: 25px 10px 0px 10px;
  }
`;
