import React from 'react';
import {StyledText} from '../styled-text/styled-text';
import {Wrapper} from './error-style';

export const Error = ({errorMessage}) => {
  return (
    <Wrapper>
      <StyledText color="#e72323">{errorMessage}</StyledText>
    </Wrapper>
  );
};
