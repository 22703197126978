import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Configurator, Home, SignIn, SignUp } from "./pages";
import { ProjectContextProvider } from "./context/project-context";
import { createTheme, ThemeProvider } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f5f4f1",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});
const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ProjectContextProvider>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/configurator" element={<Configurator />} />
              <Route exact path="/sign-in" element={<SignIn />} />
              <Route exact path="/sign-up" element={<SignUp />} />
            </Routes>
          </BrowserRouter>
        </ProjectContextProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
