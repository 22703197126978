import { useMemo, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 20px;
  width: 90%;
  padding: 0 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColorBallContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: ${({ color, selected }) =>
    selected ? `1px solid ${color}` : "1px solid #1E1E1E"};
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background-color: ${({ color }) => color};
    cursor: pointer;
  }
`;

const ColorBall = ({ color, selected, onClick }) => {
  return (
    <ColorBallContainer color={color} selected={selected}>
      <div
        onClick={() => {
          onClick && onClick();
        }}
      ></div>
    </ColorBallContainer>
  );
};

export const ColorPanel = ({ value, onChange }) => {
  const colors = useMemo(
    () => ["#e9e0d2", "#27292b", "#ff2a1b", "#8C969D", "#b9ceac", "#eaeaea"],
    []
  );
  const [count, setCount] = useState(colors.indexOf(value));
  return (
    <>
      <Container>
        {colors.map((color, index) => (
          <ColorBall
            key={index}
            color={color}
            selected={index === count}
            onClick={() => {
              setCount(index);
              onChange && onChange(color);
            }}
          />
        ))}
      </Container>
      <div
        style={{
          color: "white",
          marginTop: "8px",
          fontSize: "12px",
          marginLeft: "0px",
        }}
      >
        Color
      </div>
    </>
  );
};
