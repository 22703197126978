import React, { useMemo } from "react";
import { StyledText } from "../styled-text/styled-text";
import styled from "styled-components";
import { AddToCart } from "../../container/add-to-cart/add-to-cart";
import { useProjectContext } from "../../../context/project-context";

const Wrapper = styled.div`
  display: "flex";
  flex-direction: column;
  align-items: space-between;
  background: #1e1e1e;
  padding: 24px;
  border-radius: 10px;
`;
export const ProductInformation = ({ productName, producer, price }) => {
  const {
    data: { material },
  } = useProjectContext();
  return (
    <Wrapper>
      { producer &&
      <StyledText
        fontSize="18px"
        style={{ fontFamily: "Inter", fontWeight: "600" }}
      >
        {productName} <br />
        by {producer}
      </StyledText> }
      <StyledText
        style={{ marginTop: "30px", fontFamily: "Inter" }}
        fontSize="18px"
        fontWeight="400"
      >
        Side Table <br />
        18” x 18” x 18” <br />
        {material === "Plywood"
          ? "Premium Plywood"
          : "Powder-coated Steel"}{" "}
        <br />
        Clear satin finish <br />
      </StyledText>
      <StyledText style={{color: "#717171", fontSize: "18px", paddingTop:"12px"}} >Made in Chicago, one by one.</StyledText>
      <StyledText
        style={{ marginTop: "30px", fontFamily: "Inter" }}
        fontSize="42px"
        fontWeight="400"
      >
        $ {price}
      </StyledText>
      <AddToCart />
    </Wrapper>
  );
};
