import React, { useCallback, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import "./dropdown-style.css";
import { useProjectContext } from "../../../../context/project-context";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#1B1B1B",
    color: "white",
    border: "1px solid #4B4B4B",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "400",
    padding: "10px 0px 10px 12px",
    lineHeight: "1rem",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 10,
      // borderColor: "#80bdff",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const optionStyle = {
  backgroundColor: "#1B1B1B",
  color: "#ffffff",
  stroke: "#f00 !important",
};

const useStyles = makeStyles((theme) => ({
  margin: {
    width: "100%",
  },
}));

export const DropdownSelector = ({ options, extraStyle, value, onChange }) => {
  const { updateState } = useProjectContext();
  const classes = useStyles();
  const [count, setCount] = React.useState(options.indexOf(value) + "");
  const handleChange = useCallback(
    (event) => {
      setCount(event.target.value);
      onChange && onChange(parseInt(event.target.value) + 1);
      updateState({ isCheckout: false });
    },
    [setCount, onChange, updateState]
  );


  // Update the initial state whenever the value prop changes
  useEffect(() => {
    setCount(options.indexOf(value) + "");
  }, [value, options]);

  return (
    <div style={extraStyle}>
      <FormControl className={classes.margin}>
        <NativeSelect
          value={count}
          onChange={handleChange}
          input={<BootstrapInput />}
          className="custom_select"
        >
          {options.map((option, idx) => (
            <option key={idx} value={idx} style={optionStyle}>
              {option}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </div>
  );
};
