import leg1 from "./model/type_01.glb";
import leg2 from "./model/type_02.glb";
import leg3 from "./model/type_03.glb";
import leg4 from "./model/type_04.glb";
import leg5 from "./model/type_05.glb";

import ply1 from "./model/Plywood/ply1.glb";
import ply2 from "./model/Plywood/ply2.glb";
import ply3 from "./model/Plywood/ply3.glb";
import ply4 from "./model/Plywood/ply4.glb";

import steel1 from "./model/Steel/steel1.glb";
import steel2 from "./model/Steel/steel2.glb";
import steel3 from "./model/Steel/steel3.glb";
import steel4 from "./model/Steel/steel4.glb";

import al_base from "./images/texture/al-base.jpg";
import al_normal from "./images/texture/al-normal.jpg";
import al_roughness from "./images/texture/al-roughness.jpg";
import al_metalness from "./images/texture/al-metalness.jpg";
import wood_base from "./images/texture/wood-base.png";
import wood_normal from "./images/texture/wood-normal.png";
import gridImg from "./images/png_hair.png";
import envHDR from "./images/background/white.hdr";
// import envHDR from "./images/background/royal_esplanade_1k.hdr";

import al_base_1 from "./images/new_textures/1.jpg";
import al_base_2 from "./images/new_textures/2.jpg";
import al_base_3 from "./images/new_textures/3.jpg";
import al_base_4 from "./images/new_textures/4.jpg";
import al_base_5 from "./images/new_textures/5.jpg";
import al_base_6 from "./images/new_textures/6.jpg";

import LogoIcon from "./images/SELF_logo_v2.svg";
import TypeBG from "./images/type-bg.svg";
import UndoIcon from "./images/undo.svg";
import RedoIcon from "./images/redo.svg";
import DiceIcon from "./images/dice.svg";

import Reset from "./images/reset.svg";
import Angle from "./images/3angle.svg";
import Angles from "./images/angles.svg";
import Circle from "./images/circle.svg";
import CircleEnd from "./images/circle-end.svg";
import Type2_left_icon_1 from "./images/Type 2_slider 1_left.svg";
import Type2_left_icon_2 from "./images/Type 2_slider 1_right.svg";
import Type2_right_icon_1 from "./images/Type 2_slider 2_left.svg";
import Type2_right_icon_2 from "./images/Type 2_slider 2_right.svg";
import IsoViewIcon from "./images/iso_view.svg";
import FrontViewIcon from "./images/front_view.svg";
import TopViewIcon from "./images/top_view.svg";
import SaveIcon from "./images/save.svg";
import OpenIcon from "./images/open.svg";
import DownloadIcon from "./images/download.svg";
import WarningIcon from "./images/warning.svg";
import RadiusStart from "./images/radius-start.svg";
import RadiusEnd from "./images/radius-end.svg";
import SaveIconDisabled from "./images/save_disabled.svg";
import OpenIconDisabled from "./images/open_disabled.svg";

export const assets = {
  models: {
    legs: [leg1, leg2, leg3, leg4, leg5],
    ply: [ply1, ply2, ply3, ply4],
    steel: [steel1, steel2, steel3, steel4],
  },
  images: {
    envHDR,
    gridImg,
    wood_base,
    wood_normal,
    al_base,
    al_normal,
    al_metalness,
    al_roughness,
    textures: [
      al_base_1,
      al_base_2,
      al_base_3,
      al_base_4,
      al_base_5,
      al_base_6,
    ],
  },
  svgs: {
    Reset,
    LogoIcon,
    TypeBG,
    UndoIcon,
    RedoIcon,
    DiceIcon,
    Angle,
    Angles,
    Circle,
    CircleEnd,
    Type2_left_icon_1,
    Type2_left_icon_2,
    Type2_right_icon_1,
    Type2_right_icon_2,
    IsoViewIcon,
    FrontViewIcon,
    TopViewIcon,
    SaveIcon,
    OpenIcon,
    DownloadIcon,
    WarningIcon,
    RadiusStart,
    RadiusEnd,
    SaveIconDisabled,
    OpenIconDisabled,
  },
};
