import React, { useRef, useEffect, useState } from "react";
import './CustomPaypalLabels.css';

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { useProjectContext } from "../../../context/project-context";
import { StyledText } from "../styled-text/styled-text";
import { saveOrder, saveDesign, getDesigns } from "../../../firebase/authentication-method/authentication-methods";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#000",
  border: "1px solid white",
  borderRadius: "10px",
  p: 2,
};

const closeStyle = {
  position: "absolute",
  color: "white",
  marginLeft: "360px",
};


export const Paypal = () => {
  const {
    data: currentData,
    data: { type, material, svgPath, points, price, svgImage, legType, tableColor },
    state: { isCheckout },
    updateState,
    setMyDesigns,
    updateData,
  } = useProjectContext();

  const paypal = useRef();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [userId, setUserId]=useState("");
  const handleClose = () => {
    setOpen(false);
    updateState(!isCheckout);
  };

 useEffect(() => {
    setUserId(localStorage.getItem("userId"));
  }, []);


  // useEffect(() => {
    
  //   async function saveData () {
  //     if(!userId) {console.log("userId not available"); return;}
  //     console.log("saving userId", userId)

  //            const orderdata = {
  //           ...currentData,
  //           title: `Order- ${new Date().toLocaleString("en-US")}`,
  //           svg: `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg"><path id="blob" d="${svgPath}" fill="none" stroke="#FFFFFF" stroke-width="2px" stroke-linejoin="round"></path></svg>`,
  //           svgImage: `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg"><path id="blob" d="${svgPath}" fill="none" stroke="#FFFFFF" stroke-width="2px" stroke-linejoin="round"></path></svg>`,
  //           legType: legType,
  //           type,
  //           material,
  //           svgPath,
  //           tableColor,
  //           points: [1, 2, 3, 3],
  //         };

  //         await saveOrder(orderdata, userId);
  //         await saveDesign(orderdata, userId, true);
  //         handleOpen();
  //         const results = await getDesigns(userId);
  //         setMyDesigns(() => results); }
  //         saveData()
        
  // }, [userId]);


  useEffect(() => {
    if(paypal.current.children.length>0) return;
    if(!userId) {console.log("userId not available"); return;}

    window.paypal
      .Buttons({
       
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: new Date(),

                amount: {
                  currency_code: "USD",
                  value: price,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          await actions.order.capture();
          const orderdata = {
            ...currentData,
            title: `Order- ${new Date().toLocaleString("en-US")}`,
            svg: `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg"><path id="blob" d="${svgPath}" fill="none" stroke="#FFFFFF" stroke-width="2px" stroke-linejoin="round"></path></svg>`,
            svgImage: `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg"><path id="blob" d="${svgPath}" fill="none" stroke="#FFFFFF" stroke-width="2px" stroke-linejoin="round"></path></svg>`,    
            legType: legType,
            type,
            material,
            svgPath,
            points: [1, 2, 3, 3],
          };

          await saveOrder(orderdata, userId);
          await saveDesign(orderdata, userId, true);
          handleOpen();
          const results = await getDesigns(userId);
          setMyDesigns(() => results);
        },
        onError: (err) => {
          console.log("err", err);
        },
      })
      .render( paypal.current );
  }, [price, svgImage, legType, type, material, svgPath, points, userId]);

  return (
    <div>
      <div style={{fontColor: "#ffffff"}} ref={paypal}></div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton onClick={handleClose} style={closeStyle}>
            <CloseIcon />
          </IconButton>
          <StyledText style={{ textAlign: "center" }} fontSize="32px">
            Your Order has been received
          </StyledText>
          <StyledText
            style={{ textAlign: "center", marginTop: "10px" }}
            fontSize="16px"
          >
            Thank you for your purchase
          </StyledText>
        </Box>
      </Modal>
    </div>
  );
};
