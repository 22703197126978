import {Popover, IconButton, makeStyles, Box} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import {StyledText} from '../styled-text/styled-text';
const useStyles = makeStyles({
  root: {
    padding: 24,
    background: 'black',
    border: '1px solid white',
    borderRadius: '10px',
    width: '400px',
  },
  title: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  closeButton: {
    color: 'white',
    padding: '4px',
  },
});
export const Modal = (props) => {
  const classes = useStyles();
  const handleClose = () => {
    props.setAnchorEl(null);
  };
  const open = Boolean(props.anchorEl);

  return (
    <div>
      <Popover
        onClose={handleClose}
        open={open}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={classes.root}>
          <Box className={classes.title}>
            <StyledText fontSize="24px" fontWeight="400">
              {props.title}
            </StyledText>
            <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          {props.children}
        </Box>
      </Popover>
    </div>
  );
};
