import React from 'react';
import {ReactComponent as Logo} from '../../../assets/images/SELF_logo_v2.svg';
import {ReactComponent as MobileCom} from '../../../assets/images/mobileComputer.svg';

import {makeStyles, Box} from '@material-ui/core';
import {StyledText} from '..';

const useStyles = makeStyles({
  root: {
    padding: '40px 0px 0px 40px',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileImg: {
    marginTop: '22%',
    height: '40%',
    width: '100%',
  },
});
export default function MobileScreen() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Logo />
      <Box mt="24px" display="flex" flexDirection="column" justifyContent="space-between">
        <StyledText fontSize="29px" color="#F5F4F1">
          Hey, you.
          <br /> Please use <br /> our desktop <br /> experience <br /> for now.
        </StyledText>
      </Box>
      <Box>
        <MobileCom className={classes.mobileImg} />
      </Box>
    </Box>
  );
}
