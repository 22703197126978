import { Slider } from "@material-ui/core";

export const HSlider = ({
  value,
  onChange,
  startIcon,
  endIcon,
  min,
  max,
  step,
  label,
  extraStyle,
  onPointerUp,
}) => {
  return (
    <div style={extraStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img src={startIcon} alt="start" style={{ marginRight: "5px" }} />
        <Slider
          value={value}
          min={min}
          max={max}
          step={step}
          aria-label="value"
          color="secondary"
          onChange={(e, v) => onChange(v)}
          onPointerUp={() => {
            onPointerUp && onPointerUp();
          }}
        />
        <img src={endIcon} alt="end" style={{ marginLeft: "5px" }} />
      </div>
      <div
        style={{
          marginLeft: "0px",
          marginTop: "8px",
          fontSize: "12px",
          fontFamily: "Inter",
          color: "white",
        }}
      >
        {label}
      </div>
    </div>
  );
};
