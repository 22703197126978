import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { assets } from "../../../assets/assets";
import { useProjectContext } from "../../../context/project-context";
import {
  makeStyles,
  Box,
  InputBase,
  Paper,
  styled as stlyedM,
  Button,
} from "@material-ui/core";
import { StyledText } from "../../common/styled-text/styled-text";
import { Modal } from "../../common/modal/modal";

import {
  saveDesign,
  deleteDesign,
} from "../../../firebase/authentication-method/authentication-methods";

const useStyles = makeStyles({
  root: {
    margin: "0px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  inputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    background: "rgba(255,255,255,0.2)",
  },
  input: {
    flex: 1,
    color: "#FFFFFF",
    fontSize: "50px",
    fontFamily: "Aeonik",
  },
});
const Wrapper = styled.div`
  padding: 12px 8px 12px 8px;
  position: absolute;
  z-index: 3;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%);
  background: #020202;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 130px;
  border: 2px solid #222222;
`;

const CutomButton = stlyedM(Button)({
  width: "100%",
  fontSize: "20px",
  fontFamily: "Aeonik",
  textTransform: "none",
  background: "#FFFFFF",
  borderRadius: "15px",
  padding: "30px 10px",
  fontWeight: 600,
  "&:hover": {
    background: "#70FFCE",
  },
});
const DesignItem = ({ data, loadData, deleteData }) => {
  return (
    <>
      <div>
        <StyledText
          fontSize="54px"
          fontWeight="400"
          cursor="pointer"
          onClick={() => {
            loadData({ ...data });
          }}
        >
          {data.title}
        </StyledText>
      </div>
      <div>
        <StyledText
          fontSize="18px"
          cursor="pointer"
          onClick={() => deleteData(data)}
        >
          Delete
        </StyledText>
      </div>
    </>
  );
};

export const FileOptions = ({
  complexity,
  growth,
  legType,
  svgPath,
  currentUserId,
  myDesigns,
  type,
  setIsDesignSaved,
  setIsDesignDeleted,
}) => {
  const {
    data,
    updateData,
    currentUser,
    state: { threeApp },
  } = useProjectContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMyDesign, setAnchorMyDesign] = useState(null);
  const [inputTitle, setInputTitle] = useState("");

  const classes = useStyles();

  const saveCurrentDesign = useCallback(() => {
    if (inputTitle === "") {
      alert("Please enter the name of table");
    } else {
      const currentDesignData = {
        ...data,
        title: inputTitle,
        complexity: complexity,
        growth: growth,
        legType: legType,
        svgPath: svgPath,
        type: type,
        svgImage: document.getElementById("blobSvg").outerHTML,
        points: [1, 2, 3, 3],
      };
      saveDesign(currentDesignData, currentUserId);
      setInputTitle("");
      setAnchorEl(null);
      updateData(currentDesignData);

      setTimeout(() => {
        setIsDesignSaved((prev) => !prev);
      }, 1000);
    }
  }, [
    complexity,
    currentUserId,
    data,
    growth,
    legType,
    svgPath,
    type,
    inputTitle,
    setInputTitle,
    setAnchorEl,
    setIsDesignSaved,
    updateData,
  ]);

  const deleteSelectedDesign = useCallback(
    (data) => {
      deleteDesign(data, currentUserId);
      setAnchorMyDesign(null);
      updateData({ title: "Untitled 01" });
      const t = setTimeout(() => {
        setIsDesignDeleted((prev) => !prev);
      }, 1000);
      return () => clearTimeout(t);
    },
    [updateData, setIsDesignDeleted, currentUserId]
  );

  const downloadSvgImage = useCallback(() => {
    const formatMarkup = `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg"><path id="blob" d="${svgPath}" fill="none" stroke="#FFFFFF" stroke-width="2px" stroke-linejoin="round"></path></svg> `;
    console.log(formatMarkup);
    const url = window.URL.createObjectURL(new Blob([formatMarkup]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "newBlob.svg");
    document.body.appendChild(link);
    link.click();
    threeApp.downloadScene();
  }, [svgPath, threeApp]);

  return (
    <>
      <Wrapper>
        <div className={classes.root}>
          <img
            src={
              currentUser?.uid
                ? assets.svgs.SaveIcon
                : assets.svgs.SaveIconDisabled
            }
            onClick={
              currentUser?.uid
                ? (e) => setAnchorEl(e.currentTarget)
                : () => console.log("disabled")
            }
            style={{ cursor: currentUser?.uid ? "pointer" : "not-allowed" }}
            alt="fileSave"
          />
        </div>

        <div className={classes.root}>
          <img
            src={
              currentUser?.uid
                ? assets.svgs.OpenIcon
                : assets.svgs.OpenIconDisabled
            }
            alt="fileOpen"
            onClick={
              currentUser?.uid
                ? (e) => setAnchorMyDesign(e.currentTarget)
                : () => console.log("disabled")
            }
            style={{ cursor: currentUser?.uid ? "pointer" : "not-allowed" }}
          />
        </div>

        <div className={classes.root}>
          <img
            src={assets.svgs.DownloadIcon}
            alt="download"
            onClick={() => {
              downloadSvgImage();
            }}
          />
        </div>
      </Wrapper>
      <Box sx={{ display: "flex", padding: "15px" }}>
        <Modal
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          title={
           myDesigns &&  myDesigns[0] && myDesigns[0].length < 3
              ? "Save your design"
              : "Save limit reached"
          }
        >
          <Box sx={{ mt: 1 }}>
            <StyledText fontSize="18px">
              {myDesigns && myDesigns[0] && myDesigns[0].length < 3
                ? "You can save up 3 designs"
                : ""}
            </StyledText>
          </Box>
          {myDesigns && myDesigns[0] && myDesigns[0].length < 3 ? (
            <Box sx={{ mt: 1 }}>
              <Paper className={classes.inputRoot}>
                <InputBase
                  className={classes.input}
                  autoFocus
                  placeholder="Enter Table name"
                  value={inputTitle}
                  onChange={(e) => setInputTitle(e.target.value)}
                />
              </Paper>
              <Box sx={{ mt: 1 }}>
                <CutomButton onClick={() => saveCurrentDesign()}>
                  Save
                </CutomButton>
              </Box>
            </Box>
          ) : (
            <StyledText fontSize="20px">
              Please delete one of your designs in MyDesigns
            </StyledText>
          )}
        </Modal>

        <Modal
          anchorEl={anchorMyDesign}
          setAnchorEl={setAnchorMyDesign}
          title="My Designs"
        >
          <Box sx={{ mt: 1 }}>
            {myDesigns && myDesigns[0] && myDesigns[0].length === 0 ? (
              <StyledText fontSize="44px">
                You haven't saved anything yet.
              </StyledText>
            ) : (
              <Box>
                {myDesigns && myDesigns[0] &&
                  myDesigns[0].map((item, index) => (
                    <DesignItem
                      key={index}
                      data={item}
                      loadData={(savedData) => {
                        const { type, svgPath } = savedData;
                        updateData(savedData);
                        if (type === 1) {
                          setTimeout(() => {
                            updateData({ svgPath });
                          }, 10);
                        }
                      }}
                      deleteData={deleteSelectedDesign}
                    />
                  ))}
              </Box>
            )}
          </Box>
        </Modal>
      </Box>
    </>
  );
};
