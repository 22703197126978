export const generateStar = (outerRadius, numPoints) => {
  let starPoints = updateStar(outerRadius, numPoints);
  var path = _createSvgPath(starPoints);
  return path;
};
const updateStar = (outerRadius, numPoints) => {
  var innerRadius = 250;
  var center = Math.max(innerRadius, outerRadius);
  var angle = Math.PI / numPoints;
  var points = [];

  for (var i = 0; i < numPoints * 2; i++) {
    var radius = i & 1 ? innerRadius : outerRadius;
    points.push(center + radius * Math.sin(i * angle));
    points.push(center - radius * Math.cos(i * angle));
  }
  return points;
};

const _createSvgPath = (starPoints) => {
  const points = [];
  for (let i = 0; i < starPoints.length; i = i + 2) {
    points.push([starPoints[i], starPoints[i + 1]]);
  }

  let svgPath = '';
  var mid = [(points[0][0] + points[1][0]) / 2, (points[0][1] + points[1][1]) / 2];
  svgPath += 'M' + mid[0] + ',' + mid[1];

  for (var i = 0; i < points.length; i++) {
    var p1 = points[(i + 1) % points.length];
    var p2 = points[(i + 2) % points.length];
    mid = [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2];
    svgPath += 'Q' + p1[0] + ', ' + p1[1] + ', ' + mid[0] + ', ' + mid[1];
  }
  svgPath += 'Z';
  return svgPath;
};
