import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  tabPanel: {
    padding: "20px 24px 0px 20px",
  },
});
export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {<div className={classes.tabPanel}>{children}</div>}
    </div>
  );
}
