import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "../../context/project-context";
import { useDebounce } from "../../hooks/use-debounce";
import { SignInWrapper } from "../../components/common";
import { InputPlaceholderWrapper } from "../../components/common";
import { StyledText } from "../../components/common";
import { SignLogo } from "../../components/container";
import { SignPageFooter } from "../../components/common";
import { NextButton } from "../../components/common";
import { getUserName } from "../../utils/get-username";
import { validateEmail } from "../../utils/validateEmailAddress";
import { Error } from "../../components/common/error/error";
import { getPreapprovedUsers } from "../../firebase/authentication-method/authentication-methods";
//import { getUserName as getName } from "../../firebase/authentication-method/authentication-methods";

export const SignIn = () => {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [email, setEmail] = useState(
    window.location.hostname.includes("local")
      ? process.env.REACT_APP_EMAIL
      : ""
  );
  const [password, setPassword] = useState(
    window.location.hostname.includes("local") ? process.env.REACT_APP_PWD : ""
  );

  const [approvedUsers, setApprovedUsers] = useState([]);
  const { login, updateState } = useProjectContext();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({});

  const debouncedEmail = useDebounce(email, 1000);

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     const users = await getPreapprovedUsers();
  //     setApprovedUsers(users);
  //   };
  //   fetchUsers();
  // }, [setApprovedUsers]);

  // useEffect(() => {
  //   if (!approvedUsers) return;
  //   console.log("approvedUsers", approvedUsers);
  //   const { currentUserId, userName } = getUserName(
  //     debouncedEmail,
  //     approvedUsers
  //   );
  //   console.log("currentUserId", currentUserId);
  //   console.log("userName", userName);

  //   setCurrentUser({ name: userName, id: currentUserId });
  // }, [approvedUsers, debouncedEmail]);


  const handleSubmit = async () => {
    try {
      setError("");
      const logdetails = await login(debouncedEmail, password);
      const users = await getPreapprovedUsers(logdetails.user.uid);
      const { currentUserId, userName } = getUserName(
        debouncedEmail,
        users
      );
      console.log("currentUserId", logdetails.user.uid);
      setCurrentUser({ name: userName, id: logdetails.user.uid });
      window.localStorage.setItem("userName", userName);
      window.localStorage.setItem("userId", logdetails.user.uid);
      updateState({  isLoggedIn: true,});// isThreeLoaded: false,threeApp: null });
      navigate("/configurator");
    } catch (e) {
      setError("Wrong Password");
      setEmail("");
      setPassword("");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <SignInWrapper>
      <SignLogo />
      <div>
        {isEmailValid ? (
          <InputPlaceholderWrapper>
            <input
              id="password"
              autoComplete="off"
              type="password"
              name="password"
              required
              spellCheck="false"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="placeholder" htmlFor="password">
              <StyledText fontSize="45px">Password</StyledText>
            </span>
          </InputPlaceholderWrapper>
        ) : (
          <InputPlaceholderWrapper>
            <input
              id="email"
              autoComplete="off"
              type="text"
              name="email"
              required
              spellCheck="false"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <span className="placeholder" htmlFor="email">
              <StyledText fontSize="45px">Email address</StyledText>
            </span>
          </InputPlaceholderWrapper>
        )}

        <NextButton
          onClick={() => {
            if (isEmailValid && password !== "") handleSubmit();
            else {
              debouncedEmail !== "" &&
                validateEmail(debouncedEmail) &&
                setIsEmailValid(true);
            }
          }}
          content={isEmailValid ? "Sign in" : "Next"}
        />
        {error && <Error errorMessage={error} />}
        {debouncedEmail !== "" && !validateEmail(debouncedEmail) && (
          <Error errorMessage="Enter a valid email!" />
        )}
      </div>

      <SignPageFooter isSignUp={false} />
    </SignInWrapper>
  );
};
