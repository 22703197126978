import * as React from "react";
import { Box } from "@mui/material";
import { useProjectContext } from "../../../../context/project-context";

function TabBox({ selected, onClick, children }) {
  return (
    <Box
      padding="7px"
      border="1px solid #4b4b4b"
      borderRadius={"8px"}
      color="white"
      height={"15px"}
      width={"75px"}
      textAlign="center"
      backgroundColor={selected ? "#4b4b4b" : "#222222"}
      style={{
        cursor: "pointer",
        fontSize: "12px",
        fontFamily: "Inter",
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
}

export const TabSelector = ({ setHistory, setSvgHistory }) => {
  const {
    data: { type },
    updateData,
  } = useProjectContext();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      border="1px solid #4b4b4b"
      borderRadius={"8px"}
      padding={"8px"}
      marginTop={"-20px"}
      marginBottom={"20px"}
    >
      {Array(3)
        .fill(0)
        .map((_, idx) => (
          <TabBox
            key={idx}
            selected={idx === type - 1}
            onClick={() => {
              updateData({ type: idx + 1 });
              if (idx === 0) {
                setSvgHistory([
                  "M67.5,405Q90, 405, 225, 405Q360, 405, 382.5, 405Q405, 405, 405, 382.5Q405, 360, 405, 225Q405, 90, 405, 67.5Q405, 45, 382.5, 45Q360, 45, 225, 45Q90, 45, 67.5, 45Q45, 45, 45, 67.5Q45, 90, 45, 225Q45, 360, 45, 382.5Q45, 405, 67.5, 405Z",
                ]);
              }
              if (idx === 1) {
                updateData({ growth: 135, complexity: 4 });
                setHistory([{ growth: 135, complexity: 4 }]);
              }
              if (idx === 2) {
                updateData({ growth: 6, complexity: 12 });
                setHistory([{ growth: 6, complexity: 12 }]);
              }
            }}
          >
            Type {idx + 1}
          </TabBox>
        ))}
    </Box>
  );
};
