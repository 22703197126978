import { useRef, useEffect, useState } from "react";
import { useProjectContext } from "../../../context/project-context";
import { makeStyles } from "@material-ui/core";
import { Loading } from "../../common/loading/loading";
import ThreeJSApp from "../../../threejs-app/threejs-app";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    position: "relative",
    "& :first-child": {
      height: "100vh",
    },
    "& :nth-child(2)": {
      height: "100vh",
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
});

export const Canvas = () => {
  const classes = useStyles();
  const container = useRef(null);
  const {
    data: { legType, svgPath, tableColor, material },
    state: { view, isThreeLoaded, threeApp,  },
    updateState,
    currentUser
  } = useProjectContext();

  useEffect(() => {
    if (threeApp  ) {
      //console.log('i return', threeApp, isThreeLoaded)
      
      return;}
    (async () => {
      //console.log("async constructor threejs")
      const threeApp = new ThreeJSApp(container.current, updateState);
      await threeApp.start(svgPath);
      updateState({ threeApp });
      //return updateState({threeApp: null})
    })();
  }, [updateState, threeApp, svgPath,  isThreeLoaded]);



  useEffect(() => {
    if (!threeApp || !isThreeLoaded) return;
    threeApp.changeMaterial(material, tableColor);
  }, [tableColor, threeApp, material, isThreeLoaded]);

  useEffect(() => {
    if (!threeApp || !isThreeLoaded) return;
    threeApp.updateSVGMesh(svgPath);
  }, [svgPath, threeApp, isThreeLoaded]);

  useEffect(() => {
    if (!threeApp || !isThreeLoaded) return;
    threeApp.changeLeg(legType);
    threeApp.changeMaterial(threeApp.material, threeApp.tableColor);
  }, [legType, threeApp, isThreeLoaded]);

  useEffect(() => {
    if (!threeApp || !isThreeLoaded) return;
    threeApp.changeView(view);
  }, [view, threeApp, isThreeLoaded]);

  return (
    <div >
      {!isThreeLoaded && <Loading />}
      { <div ref={container} className={classes.root}></div>}
      
    </div>
  );
};
