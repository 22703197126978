import styled from 'styled-components';
export const Wrapper = styled.div`
  height: 100vh;
  .home-logo {
    margin-top: 70px;
    @media (max-width: 1800px) {
      max-width: 1600px;
    }
    @media (max-width: 1600px) {
      max-width: 1400px;
    }
    @media (max-width: 1400px) {
      max-width: 1200px;
    }
    @media (max-width: 1200px) {
      max-width: 1000px;
      margin-top: 0px;
    }
    @media (max-width: 1000px) {
      max-width: 800px;
    }
    @media (max-width: 768px) {
      max-width: 600px;
    }
    @media (max-width: 600px) {
      max-width: 500px;
    }
    @media (max-width: 515px) {
      max-width: 450px;
    }
    @media (max-width: 450px) {
      max-width: 400px;
    }
    @media (max-width: 414px) {
      max-width: 350px;
      height: 200px;
      margin-top: 20px;
    }
    @media (max-width: 360px) {
      max-width: 350px;
      height: 145px;
      margin-top: 20px;
    }
  }
  @media (max-width: 414px) {
    height: 100%;
    div {
      svg {
        height: 25px;
      }
    }
  }
  @media (max-width: 360px) {
    div {
      svg {
        height: 25px;
        margin-top: 25px !important;
      }
    }
  }
`;

export const HeroBottom = styled.div`
  position: relative;
  margin-top: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :before {
    content: '';
    position: absolute;
    left: 50%;
    top: 200%;
    height: 4px;
    width: 5%;
    border-bottom: 3px solid white;
  }
  @media (max-width: 1200px) {
    div {
      font-size: 45px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;

    margin-top: 70px;
    align-items: center;
    justify-content: center;
    text-align: center;
    div {
      margin-top: 10px;
      font-size: 40px;
      display: flex;
      align-items: center;
      svg {
        margin-top: 30px;
      }
    }
    :before {
      top: 130%;
    }
  }
  @media (max-width: 414px) {
    margin-top: 20px;
    div {
      text-align: center;
      font-size: 25px;
    }
    :before {
      top: 110%;
    }
  }
`;
export const BetaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 450px) {
    margin-right: 20px;
  }
`;
