import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import hdrBackgroundLow from "../assets/images/background/white.hdr";
import hdrBackgroundHigh from "../assets/images/background/surgery.hdr";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import gsap from "gsap";
// import { TweenMax, Power1 } from 'gsap';

import { assets } from "../assets/assets";

export default class ThreeJSApp {
  constructor(container, updateState) {

    // this.handleLoading = handleLoading;
    this.color = "#e9e0d2";
    this.textures = [];
    this.tableColor = "#ffffff";
    this.legType = 1;
    this.material = "Steel";

    this.currentLeg = null;
    this.container = container;
    this.updateState = updateState;

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      45,
      this.container.offsetWidth / this.container.offsetHeight,
      0.1,
      1000
    );

    this.camera.position.set(0.01, 33, 0.01);

    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(
      this.container.offsetWidth,
      this.container.offsetHeight
    );
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.VSMShadowMap;
    this.container.appendChild(this.renderer.domElement);

    this.renderer1 = new THREE.WebGLRenderer({ antialias: true });

    this.renderer1.setSize(
      this.container.offsetWidth,
      this.container.offsetHeight
    );

    this.scene1 = new THREE.Scene();
    this.camera1 = new THREE.PerspectiveCamera(
      45,
      this.container.offsetWidth / this.container.offsetHeight,
      0.1,
      1000
    );
    this.camera1.position.set(0, 0, 20);
    this.scene1.add(this.camera1);
    this.addLights();

    this.container.appendChild(this.renderer1.domElement);
    this.renderer1.domElement.style.zIndex = "-1";

    this.orbit = new OrbitControls(this.camera, this.renderer.domElement);
    this.orbit.target.set(0, -5, 0);
    this.orbit.enablePan = false;
    this.orbit.addEventListener("change", this.render);
    this.orbit.maxPolarAngle = Math.PI / 2;
    this.orbit.maxDistance = 60;
    this.orbit.minDistance = 30;
    this.orbit.update();


    window.addEventListener(
      "resize",
      () => {
        this.camera.aspect =
          this.container.offsetWidth / this.container.offsetHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(
          this.container.offsetWidth,
          this.container.offsetHeight
        );
        this.camera1.aspect =
          this.container.offsetWidth / this.container.offsetHeight;
        this.camera1.updateProjectionMatrix();
        this.renderer1.setSize(
          this.container.offsetWidth,
          this.container.offsetHeight
        );
        this.render();
      },
      false
    );
  }

  async start(svgPath) {
   
    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    pmremGenerator.compileEquirectangularShader();
   
    const envTexture = await
    new RGBELoader()
      .setPath()
      .loadAsync(hdrBackgroundLow).then((envTexture) => {
    this.scene.environment =
      pmremGenerator.fromEquirectangular(envTexture).texture;
    this.scene1.environment =
      pmremGenerator.fromEquirectangular(envTexture).texture;

    envTexture.dispose(); 
    this.render();
    });

   
    // add grid plane to camera
    const txLoader = new THREE.TextureLoader();
    const bgGrid = await txLoader.loadAsync(assets.images.gridImg);

    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(20, 20),
      new THREE.MeshLambertMaterial({ map: bgGrid, color: 0x949494 })
    );
    plane.rotation.z = Math.PI / 2;
    plane.scale.set(0.82, 0.82, 0.82);

    this.scene1.add(plane);

    {
      let src = assets.images.textures[0];
      const t = await txLoader.loadAsync(src);
      this.textures.push(t);

      for(let i = 1 ; i < assets.images.textures.length; i++) {
        await txLoader.loadAsync(assets.images.textures[i]).then((t) => {
          this.textures.push(t);
          this.render();
        })
      }
    }

    // this.alMap = await txLoader.loadAsync(assets.images.al_base);
    await txLoader.loadAsync(assets.images.al_base).then((texture) => {
      this.alMap = texture;
      this.updateMap(this.alMap);
    });
    // this.alNormalMap = await txLoader.loadAsync(assets.images.al_normal);
    await txLoader.loadAsync(assets.images.al_normal).then((texture) => {
      this.alNormalMap = texture;
      this.updateMap(this.alNormalMap);
    });

    // this.alTopMap = await txLoader.loadAsync(assets.images.al_base);
    await txLoader.loadAsync(assets.images.al_base).then((texture) => {
      this.alTopMap = texture;
      this.updateMap(this.alTopMap, 0.0035, 0.002);
    });

    this.woodTopMap =  await txLoader.loadAsync(assets.images.wood_base);
    this.updateMap(this.woodTopMap, 0.0035, 0.002);
    // txLoader.loadAsync(assets.images.wood_base).then((texture) => {
    //   this.woodTopMap = texture;
    // });

    // this.updateMap(this.alMap);
    // this.updateMap(this.alNormalMap);

    // this.updateMap(this.alTopMap, 0.0035, 0.002);
    // this.updateMap(this.woodTopMap, 0.0035, 0.002);


    this.plyModels = [];
    this.steelModels = [];
    const loader = new GLTFLoader();

    for (const url of [...assets.models.ply]) {
      // const mesh = (loader.loadAsync(url)).scene.children[0];
      loader.loadAsync(url).then((gltf) => {
        const mesh = gltf.scene.children[0];
        this.woodMap = mesh.material.map;
        this.woodNormalMap = mesh.material.normalMap;
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        this.plyModels.push(mesh);
      });
    }

    {
      const mesh = (await loader.loadAsync(assets.models.steel[0])).scene.children[0];
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      this.steelModels.push(mesh);
      for(let i = 1 ; i < assets.models.steel.length; i++) {
        loader.loadAsync(assets.models.steel[i]).then((gltf) => {
          const mesh = gltf.scene.children[0];
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          this.steelModels.push(mesh);
        })
      }
    }

    this.changeLeg(1);
    this.updateSVGMesh(svgPath);
    this.changeMaterial(this.material, this.color);
    this.render();


    this.updateState({ isThreeLoaded: true });
    

      gsap.to(
      this.camera.position,
      {
        x: 33,
        y: 3.2,
        z: 20,
        duration: 3,
        onUpdate: () => {this.orbit.update()
        this.render()},
      },
      "+0.5"
    ).then(() =>{
      new RGBELoader()
        .setPath()
        .loadAsync(hdrBackgroundHigh).then((envTexture) => {
          this.scene.environment =
          pmremGenerator.fromEquirectangular(envTexture).texture;
          this.scene1.environment =
          pmremGenerator.fromEquirectangular(envTexture).texture;
          
          envTexture.dispose(); 
          pmremGenerator.dispose();
          this.render();
        });
      this.render();
    });
  }








  
  addLights = () => {
    const ambientLight = new THREE.AmbientLight(0xffffff);
    ambientLight.position.set(0, 10, 0);
    this.scene1.add(ambientLight);
    
    const directionLight1 = new THREE.DirectionalLight(0xffffff);
    directionLight1.castShadow = true;
    directionLight1.shadow.camera.near = 0.1;
    directionLight1.shadow.camera.far = 500;
    directionLight1.shadow.camera.right = 17;
    directionLight1.shadow.camera.left = -17;
    directionLight1.shadow.camera.top = 17;
    directionLight1.shadow.camera.bottom = -17;
    directionLight1.shadow.mapSize.width = 1024;
    directionLight1.shadow.mapSize.height = 1024;
    directionLight1.shadow.radius = 4;
    directionLight1.shadow.bias = -0.0005;
    directionLight1.position.set(-8, 10, -14);
    this.scene.add(directionLight1);

    this.dirLight = new THREE.DirectionalLight(0xffffff);
    this.dirLight.position.set(3, 15, 17);
    this.dirLight.castShadow = true;
    this.dirLight.shadow.camera.near = 0.1;
    this.dirLight.shadow.camera.far = 500;
    this.dirLight.shadow.camera.right = 17;
    this.dirLight.shadow.camera.left = -17;
    this.dirLight.shadow.camera.top = 17;
    this.dirLight.shadow.camera.bottom = -17;
    this.dirLight.shadow.mapSize.width = 1024;
    this.dirLight.shadow.mapSize.height = 1024;
    this.dirLight.shadow.radius = 4;
    this.dirLight.shadow.bias = -0.0005;

    this.scene.add(this.dirLight);
  };

  updateMap(map, rx, ry) {
    map.wrapS = map.wrapT = THREE.RepeatWrapping;
    map.repeat.set(rx ? rx : 1, ry ? ry : 1);
    map.encoding = THREE.sRGBEncoding;
  }

  changeMaterial = (matName, color) => {
    const colors = [
      "#e9e0d2",
      "#27292b",
      "#ff2a1b",
      "#8C969D",
      "#b9ceac",
      "#eaeaea",
    ];
    const idx = colors.indexOf(color);
    this.material = matName;
    this.changeLeg(this.legType);
    this.updateSVGMesh(this.svgPath);
    this.tableColor = color;

    if (matName === "Steel") {
      this.currentLeg.material.map = this.textures[idx]; //this.alMap;
      this.currentLeg.material.color = new THREE.Color(color);
      this.currentLeg.material.normalMap = this.alNormalMap;
      this.currentLeg.material.roughness = 0.25;
      this.currentLeg.material.metalness = 0.7;

      this.blobMesh.material.map = this.textures[idx]; //this.alTopMap;
      this.blobMesh.material.color = new THREE.Color(color);
      this.blobMesh.material.roughness = 0.25;
      this.blobMesh.material.metalness = 0.7;
    } else {
      this.currentLeg.material.color = new THREE.Color(0xffffff);
      this.currentLeg.material.map = this.woodMap;
      this.currentLeg.material.normalMap = this.woodNormalMap;
      this.currentLeg.material.roughness = 1;
      this.currentLeg.material.metalness = 0;

      this.blobMesh.material.color = new THREE.Color(0xffffff);
      this.blobMesh.material.map = this.woodTopMap;
      this.blobMesh.material.roughness = 1;
      this.blobMesh.material.metalness = 0;
    }

    this.render();
  };

  changeLeg = (legIndex) => {
    this.legType = legIndex;

    if (this.currentLeg) {
      this.currentLeg.geometry.dispose();
      this.currentLeg.material.dispose();
      this.scene.remove(this.currentLeg);
    }
    const currentLeg =
      this.material === "Plywood"
        ? this.plyModels[legIndex - 1].clone()
        : this.steelModels[legIndex - 1].clone();
    currentLeg.scale.set(27, 27, 27);
    currentLeg.position.set(0, this.material === "Plywood" ? -12.7 : -12.5, 0);
    this.currentLeg = currentLeg;
    this.scene.add(this.currentLeg);
    this.render();
  };

  render = () => {
    this.renderer.render(this.scene, this.camera);
    this.renderer1.render(this.scene1, this.camera1);
  };

  updateSVGMesh(svgPath) {
    this.svgPath = svgPath;
    const svgData = new SVGLoader().parse(`
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg"><path id="blob" d="${svgPath}" fill="none" stroke="#FFFFFF" stroke-width="2px" stroke-linejoin="round"></path></svg> 
      `);
    const shapes = svgData.paths[0].toShapes(true);
    const newGeo = new THREE.ExtrudeGeometry(shapes, {
      depth: this.material === "Plywood" ? 12 : 2,
      bevelEnabled: false,
    });
    newGeo.center();

    if (this.blobMesh) {
      const oldGeo = this.blobMesh.geometry;
      this.blobMesh.geometry = newGeo;
      oldGeo.dispose();
    } else {
      this.blobMesh = new THREE.Mesh(
        newGeo,
        new THREE.MeshStandardMaterial({
          map: this.woodTopMap,
        })
      );
      this.blobMesh.castShadow = true;
      this.blobMesh.receiveShadow = true;
      this.blobMesh.scale.set(0.0291, 0.0291, 0.038);
      this.blobMesh.rotation.set(1.57, 0, 0);
      this.scene.add(this.blobMesh);
    }
  this.render();
  }

  changeView = (view) => {
    const pos = {
      top: { x: -0.2, y: 27.45, z: 22.42 },
      front: { x: -39.3, y: 0.2, z: -5.4 },
      isometric: { x: -11, y: 18, z: 30 },
    };
    const timeline = gsap.timeline();
    timeline.to(this.camera.position, {
      duration: 1.5,
      ...pos[view],
      onUpdate: () => {
        this.orbit.update();
      },
      ease: "expo.out",
    });
  };

  downloadScene() {
    let a = document.createElement("a");
    a.href = this.renderer.domElement
      .toDataURL()
      .replace("image/png", "image/octet-stream");
    a.download = "canvas.png";
    a.click();
  }
}
