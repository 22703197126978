import {ReactComponent as Logo} from '../../../assets/images/SELF_logo_v2.svg';
import {ReactComponent as Beta} from '../../../assets/images/beta.svg';
export const SignLogo = () => {
  return (
    <div
      style={{
        marginTop: '-100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <a href="http://selffurnished.com/">
        <Logo />
      </a>
      <Beta />
    </div>
  );
};
