import React, { useMemo } from "react";
import styled from "styled-components";
import { assets } from "../../../assets/assets";
import { useProjectContext } from "../../../context/project-context";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    margin: "0px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  img: {
    width: "30px",
    opacity: 0.5,
  },
  active: {
    opacity: 1,
  },
});

const Wrapper = styled.div`
  padding: 20px 6px 20px 6px;
  position: absolute;
  z-index: 3;
  width: 40px;
  height: 150px;
  bottom: 50%;
  right: calc(30%);
  transform: translate(50%, 50%);
  background: #020202;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  border: 2px solid #222222;
`;

const Item = ({ selected, onClick, icon }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        src={icon}
        className={`${classes.img} ${selected ? classes.active : ""}`}
        onClick={onClick}
        alt=""
      />
    </div>
  );
};

export const CameraView = () => {
  const {
    state: { view },
    updateState,
  } = useProjectContext();
  const labels = useMemo(() => ["top", "front", "isometric"], []);
  const icons = useMemo(
    () => [
      assets.svgs.TopViewIcon,
      assets.svgs.FrontViewIcon,
      assets.svgs.IsoViewIcon,
    ],
    []
  );
  return (
    <Wrapper>
      {labels.map((label, idx) => (
        <Item
          key={idx}
          icon={icons[idx]}
          selected={label === view}
          onClick={() => {
            updateState({ view: labels[idx] });
          }}
        />
      ))}
    </Wrapper>
  );
};
