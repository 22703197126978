import React, { useState, useContext, useEffect, useCallback } from "react";
/* eslint-disable */
import { app } from "../firebase/firebase";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { generator } from "../utils/generate-blob";
import { generateStar } from "../utils/generate-star";
// import {getPreapprovedUsers} from '../firebase/authentication-method/authentication-methods';
const auth = getAuth();

export const ProjectContext = React.createContext();

export const useProjectContext = () => {
  return useContext(ProjectContext);
};

export const ProjectContextProvider = ({ children }) => {
  const [data, setData] = useState({
    historyCount: 1,
    title: "Untitled 01",
    svgPath:
      "M67.5,405Q90, 405, 225, 405Q360, 405, 382.5, 405Q405, 405, 405, 382.5Q405, 360, 405, 225Q405, 90, 405, 67.5Q405, 45, 382.5, 45Q360, 45, 225, 45Q90, 45, 67.5, 45Q45, 45, 45, 67.5Q45, 90, 45, 225Q45, 360, 45, 382.5Q45, 405, 67.5, 405Z",
    points: [
      [45, 405],
      [405, 405],
      [405, 45],
      [45, 45],
    ],
    // svgPathType1:
    //   "M67.5,405Q90, 405, 225, 405Q360, 405, 382.5, 405Q405, 405, 405, 382.5Q405, 360, 405, 225Q405, 90, 405, 67.5Q405, 45, 382.5, 45Q360, 45, 225, 45Q90, 45, 67.5, 45Q45, 45, 45, 67.5Q45, 90, 45, 225Q45, 360, 45, 382.5Q45, 405, 67.5, 405Z",
    // svgPathType2: generateStar(parseInt(6), parseInt(12)),
    // svgPathType3: generator({
    //   size: 500,
    //   growth: 6,
    //   edges: 12,
    //   seed: null,
    // }),

    borderRadius: 40,
    growth: 6,
    complexity: 12,
    legType: 1,
    tableColor: "#e9e0d2",
    userName: "",
    type: 1,
    material: "Steel",
    svgImage:
      '<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg"><path id="blob" d="M453.22037,303.7224Q435.76554, 357.4448, 399.61815, 402.13221Q363.47076, 446.81963, 306.73538, 465.42936Q250, 484.0391, 189.67846, 471.57808Q129.35692, 459.11706, 91.71802, 411.33036Q54.07913, 363.54366, 31.39575, 306.77183Q8.71237, 250, 56.38898, 207.80324Q104.0656, 165.60648, 130.00064, 126.75882Q155.93567, 87.91116, 202.96784, 91.84746Q250, 95.78377, 297.01143, 90.85972Q344.02285, 85.93566, 390.96665, 113.70381Q437.91045, 141.47195, 454.29283, 195.73598Q470.6752, 250, 453.22037, 303.7224Z" fill="none" stroke="#FFFFFF" stroke-width="2px" stroke-linejoin="round"></path></svg>',
    price: 550,
  });

  const [state, setState] = useState({
    view: "",
    isLoggedIn: true,
    isThreeLoaded: false,
    isCheckout: false,
    threeApp: null,
  });
  const [myDesigns, setMyDesigns] = useState([]);
  const updateData = useCallback(
    (newItem) => {
      setData((prev) => ({ ...prev, ...newItem }));
    },
    [setData]
  );

  const updateState = useCallback(
    (newItem) => {
      setState((prev) => ({ ...prev, ...newItem }));
    },
    [setState]
  );

  /* user authentication */

  const [currentUser, setCurrentUser] = useState(null);

  const [loading, setLoading] = useState(true);

  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    // Sign out the user using Firebase auth
    auth
      .signOut()
      .then(() => {
        // Clear the local storage
        localStorage.clear();
        setState(state=>({
          ...state,
          isCheckout: false,
        }))
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(email);
  };

  const updatePassword = (password) => {
    return currentUser.updatePassword(password);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);

      //updateState({ isLoggedIn: !!user });
      setLoading(false);
      // getApprovedUsers();
    });
    return unsubscribe;
  }, []);

  const value = {
    data,
    updateData,
    state,
    myDesigns,
    setMyDesigns,
    updateState,
    currentUser,
    signup,
    login,
    logout,
    updatePassword,
    resetPassword,
  };
  return (
    <ProjectContext.Provider value={value}>
      {!loading && children}
    </ProjectContext.Provider>
  );
};
