import {
  doc,
  updateDoc,
  setDoc,
  collection,
  getDocs,
  getDoc,
  arrayUnion,
  arrayRemove,
} from "@firebase/firestore";
import { db } from "../firebase";

export const getPreapprovedUsers = async (userCollectionId) => {
  try {
    // const results = await getDocs(collection(db, "users"));
    const docRef = doc(db, "users", userCollectionId);
    const results = (await getDoc(docRef)).data();

    return [results];
  } catch (e) {
    console.error(e);
  }
};

export const addUser = async (userData) => {
  try {
    const currentUserRef = doc(db, "users", `/${userData.id}`);

    const result = await setDoc(currentUserRef, {
      name: userData.name,
      email: userData.email,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};


export const getDesigns = async (userCollectionId) => {
  try {
    console.log(userCollectionId)
    //const results = await getDocs(collection(db, `users`));

    const docRef = doc(db, "users", userCollectionId);
    const results = await getDoc(docRef);
    
    const savedDesigns = [];
      if (results) {
        savedDesigns.push(results.data().designs);
      }
 

    if (savedDesigns[0] === undefined) savedDesigns[0] = [];
    return savedDesigns;
  } catch (e) {
    console.log(e);
  }
};

// export const getUserName = async (userCollectionId) => {
//   try {
//     console.log(userCollectionId)
//     //const results = await getDocs(collection(db, `users`));

//     const docRef = doc(db, "users", userCollectionId);
//     const results = await getDoc(docRef);
//     return results.data().name;
 
//   } catch (e) {
//     console.log(e);
//   }
// };



export const saveDesign = async (designData, userCollectionId, checkout=false) => {
  try {
    const currentUserRef = doc(db, "users", `/${userCollectionId}`);
    await updateDoc(currentUserRef, {
      designs: arrayUnion(designData),
    });
    if (checkout) {console.log("checkout:design saved")}
    else{
    alert("Design saved.");}
  } catch (e) {
    console.log(e);
  }
};



export const saveOrder = async (orderData, userId) => {
  try {
    console.log("orderData", orderData,"userId" , userId)
    const currentUserRef = doc(db, "users", `/${userId}`);
    await updateDoc(currentUserRef, {
      orders: arrayUnion(orderData),
    });
  } catch (e) {
    console.log(e);
  }
};
export const deleteDesign = async (designData, userCollectionId) => {
  try {
    const currentUserRef = doc(db, "users", `/${userCollectionId}`);
    await updateDoc(currentUserRef, {
      designs: arrayRemove(designData),
    });
    alert("Design deleted.");
  } catch (e) {
    console.log(e);
  }
};
