import React, { useState, useEffect, useCallback } from "react";
import pathast from "path-ast";
import "./draw-svg.css";
import Canvas from "./Canvas";
import data from "./data";
import { useProjectContext } from "../../../context/project-context";
import { _createSvgPath } from "../../../utils/generate-blob";

let pts = [];
let astHistory = [];
let newAST = {};

export const DrawSVG = ({ setSvgHistory }) => {
  const {
    data: { historyCount, type, borderRadius, svgPath },
    updateData,
  } = useProjectContext();
  const [state, setState] = useState({
    ast: pathast.parse("M5 95 L95 95 L95 5 L5 5 Z"),
    current: 1, // current point
    selected: false, // full path selected
    width: 100,
    height: 100,
    zoom: 2.85,
    grid: true,
    resolution: 2,
    snap: false,
    preview: false,
    mode: "select",
    isPointMoving: false,
    isTranslating: false,
    isScaling: false,
    transformParams: false,
    transformStart: false,
  });
  const updateState = useCallback((newItem) => {
    setState((prev) => ({ ...prev, ...newItem }));
  }, []);

  // initialize
  useEffect(() => {
    astHistory = [pathast.parse("M5 95 L95 95 L95 5 L5 5 Z")];
  }, [type]);

  // update AST
  useEffect(() => {
    if (historyCount <= astHistory.length)
      updateState({ ast: astHistory[historyCount - 1] });
  }, [updateState, historyCount]);

  const updateAst = (ast) => {
    const scale = 4;
    pts = [];

    if (ast.commands.length > 11) return;

    newAST = { ...ast };
    updateState({ ast: ast });

    ast.commands.forEach((e) => {
      if (e.params.x && e.params.y) {
        pts.push([e.params.x * scale, e.params.y * scale]);
      }
    });

    updateData({
      points: pts,
      svgPath: _createSvgPath(expandPts(pts, borderRadius)),
    });
  };

  //reset
  useEffect(() => {
    if (
      svgPath ===
      "M67.5,405Q90, 405, 225, 405Q360, 405, 382.5, 405Q405, 405, 405, 382.5Q405, 360, 405, 225Q405, 90, 405, 67.5Q405, 45, 382.5, 45Q360, 45, 225, 45Q90, 45, 67.5, 45Q45, 45, 45, 67.5Q45, 90, 45, 225Q45, 360, 45, 382.5Q45, 405, 67.5, 405Z"
    )
      updateData({
        points: [
          [45, 405],
          [405, 405],
          [405, 45],
          [45, 45],
        ],
      });
  }, [svgPath, updateData]);

  const onMouseUp = () => {
    if (pts.length === 0) return;

    astHistory.push(newAST);
    setSvgHistory((prev) => [
      ...prev,
      _createSvgPath(expandPts(pts, borderRadius)),
    ]);
    updateData({ historyCount: astHistory.length });
  };

  const handleChange = (e) => {
    let key = e.target.name;
    let val = parseFloat(e.target.value) || e.target.value;
    updateState({ [key]: val });
  };

  const selectPoint = (i) => {
    updateState({ selected: false, current: i });
  };

  const toggle = (key) => {
    let val = !this.state[key];
    updateState({ [key]: val });
  };

  return (
    <div
      style={{
        color: "white",
        backgroundColor: data.colors.dark,
      }}
    >
      <Canvas
        {...data}
        {...state}
        toggle={toggle}
        updateAst={updateAst}
        selectPoint={selectPoint}
        updateState={updateState}
        handleChange={handleChange}
        onMouseUp={onMouseUp}
      />
    </div>
  );
};

let safePts = [];
export function expandPts(pts, r = 10) {
  const newPts = [];
  let detected = false;
  pts.forEach((pt, idx) => {
    let x1, y1, x2, y2, mx1, my1, mx2, my2;
    if (idx === pts.length - 1) {
      x1 = pt[0];
      y1 = pt[1];
      x2 = pts[0][0];
      y2 = pts[0][1];
    } else {
      x1 = pt[0];
      y1 = pt[1];
      x2 = pts[idx + 1][0];
      y2 = pts[idx + 1][1];
    }

    if (dist([x1, y1], [x2, y2]) < r) detected = true;

    if (x1 === x2 && y1 === y2) {
      mx1 = x1;
      my1 = x1;
    } else {
      const alpha = Math.atan((y2 - y1) / (x2 - x1));
      if (x2 >= x1) {
        mx1 = x1 + r * Math.cos(alpha);
        my1 = y1 + r * Math.sin(alpha);

        mx2 = x2 - r * Math.cos(alpha);
        my2 = y2 - r * Math.sin(alpha);
      } else {
        mx1 = x1 - r * Math.cos(alpha);
        my1 = y1 - r * Math.sin(alpha);

        mx2 = x2 + r * Math.cos(alpha);
        my2 = y2 + r * Math.sin(alpha);
      }
    }

    newPts.push([x1, y1]);
    newPts.push([mx1, my1]);
    newPts.push([mx2, my2]);
  });

  if (detected) return safePts;
  safePts = newPts;
  return newPts;
}

function dist(x, y) {
  return Math.sqrt(Math.pow(x[0] - y[0], 2) + Math.pow(x[1] - y[1], 2));
}
