import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Canvas, Control } from "../../components/container";
import { ConfiguratorHero } from "./configurator-hero/configurator-hero";
import { ConfiguratorWrapper } from "./configurator-style";
import { useProjectContext } from "../../context/project-context";

export const Configurator = () => {
  const {
    state: { isLoggedIn },
  } = useProjectContext();

  if (!isLoggedIn) {
    return <Navigate to="/sign-in" replace={true} />;
  }



  return (
    <>
      <ConfiguratorWrapper>
        <Control />
        <Canvas />
        <ConfiguratorHero />
      </ConfiguratorWrapper>
    </>
  );
};

export default Configurator;
