import styled from "styled-components";
import { assets } from "../../../assets/assets";

export const Wrapper = styled.div`
  width: 350px;
  padding: 35px 38px 0px 45px;
  z-index: 10;
  /* @media (max-width: 1440px) {
    padding: 55px 22px 0px 22px;
  }
  @media (max-width: 1024px) {
    padding: 25px 10px 0px 10px;
  } */
`;

export const ControlWrapper = styled.div`
  background-color: #222222;
  border-radius: 10px;
`;

export const ControlPanelWrapper = styled.div`
  padding: 0px 24px 30px 24px;
  @media (max-width: 1024px) {
    padding: 7px 18px;
  }
`;

export const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
`;
export const SvgWrapperCore = styled.div`
  background-color: #0d0c0c;
  background-image: url(${assets.svgs.TypeBG});
  /* background-repeat: repeat; */
  width: 308px;
  border: 1px solid #616161;
  justify-content: center;
  svg {
    /* @media (max-width: 1024px) {
      height: 150px;
    } */
  }
`;

export const Warning = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-Top: -20px;
  margin-bottom: 40px;
  background-color: #171717;
  padding: 10px;
  & > img {
    padding-top: 5px;
  }

  & > div {
    width: 250px;
    color: white;
    font-size: 13px;
    font-family: Acumin;
  }
`;

export const SliderWrapper = styled.div`
  color: #1e1e1e;
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    margin-bottom: 17px;
  }
  div {
    display: flex;

    justify-content: space-between;
    align-items: center;
    button {
      padding: 0px;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Tabsa = styled.div`
  padding: 15px;
  display: flex;

  flex-wrap: wrap;

  button {
    width: 50%;
    padding: 5px 0px;
  }
`;
